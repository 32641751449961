import React from "react"

const SkipBar = ({ barProgress }) => {
  return (
    <div className="skip-bar" style={{ width: "24px" }}>
      <div
        className="skip-bar-bg"
        role="button"
        aria-label="Seek time in video"
      />
      <div
        className="skip-bar-played"
        role="button"
        aria-label="Seek time in video"
        style={{
          width: 24 * barProgress + "px",
        }}
      />
    </div>
  )
}

export default SkipBar
