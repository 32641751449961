import { navigate } from "gatsby"
import React from "react"
import getProfileProps from "../../utils/getProfileProps"
import Video from "../Video/Video"
import "./VisitingRoom.css"

const VisitingRoom = ({ pageContext, ...otherContext }) => {
  const { profileId, full_name, color, nextProfile, video_link } = pageContext

  const { age_at_offense, current_age } = getProfileProps(pageContext)

  return (
    <div className={`visiting-room-wrap container`}>
      <Video
        profileId={profileId}
        name={full_name.text}
        age_at_offense={age_at_offense}
        current_age={current_age}
        color={color}
        onClose={() => {
          navigate("/visiting-room")
        }}
        nextProfile={nextProfile}
        hasCaptions
        video_link={video_link && video_link.url}
      />
    </div>
  )
}

export default VisitingRoom
