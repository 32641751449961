import React, { useEffect, useRef } from "react"

const TranscriptParagraph = ({
  getSeconds,
  setProgress,
  paragraph,
  isActive,
  isFirstActive,
  isPast,
  duration,
  onSeek,
  handleKeyUp,
  isScrolling,
  isPlaying,
}) => {
  const ref = useRef()

  useEffect(() => {
    if (isActive && isFirstActive && !isScrolling && isPlaying && ref.current)
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
  }, [isActive, isScrolling, isPlaying, isFirstActive])

  return (
    <div
      className={`transcript-paragraph ${isActive ? "active" : ""} ${
        isPast ? "past" : ""
      }`}
      ref={ref}
      onClick={() => {
        setProgress({
          progress: getSeconds(paragraph.time) / duration,
          progressSeconds: +getSeconds(paragraph.time),
        })
        onSeek(getSeconds(paragraph.time))
      }}
      onKeyUp={(ev) =>
        handleKeyUp(ev, () => {
          setProgress({
            progress: getSeconds(paragraph.time) / duration,
            progressSeconds: +getSeconds(paragraph.time),
          })
          onSeek(getSeconds(paragraph.time))
        })
      }
      role="button"
      tabIndex="0"
    >
      <div className="block">
        <span className="speaker">{paragraph.speaker}</span>
        <span className="time">{paragraph.time}</span>
      </div>
      <p className="content">{paragraph.content}</p>
      <div className="separator"></div>
    </div>
  )
}

export default TranscriptParagraph
