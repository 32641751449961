import Player from "@vimeo/player"
import isNull from "lodash/isNull"
import React, { forwardRef, useEffect, useRef, useState } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { handleKeyUp } from "../../utils"
import useKeyPress from "../../utils/useKeyPressed"
import Loading from "../Loading"
import Play from "../Symbols/Play"
import Transcript from "./Transcript"
import VideoNext from "./VideoNext"
import "./VideoPlayer.css"
import VideoPlayerControls from "./VideoPlayerControls"

const getUrl = (videoSrcURL) => {
  if (videoSrcURL) {
    try {
      return videoSrcURL.split("https://vimeo.com/")[1]
    } catch {
      return videoSrcURL.split("/archive/")[1]
    }
  }
}

const VideoPlayer = forwardRef(
  (
    {
      videoSrcURL,
      name,
      color,
      duration,
      nextProfile,
      showTranscript,
      setDuration,
      setShowTranscript,
      isLastTenSeconds,
      progress,
      setProgress,
      texttrack,
      hasTranscript,
      hasCaptions,
      startTime,
      profileId,
      onClose,
    },
    videoPlayerRef
  ) => {
    const [isLoading, setLoading] = useState(false)
    const [isPlaying, setPlaying] = useState(false)
    const [isPaused, setPause] = useState(false)
    const [videoPlayer, setVideoPlayer] = useState(null)
    const [showControls, setShowControls] = useState(true)
    const [showCaptions, setShowCaptions] = useState(texttrack)

    const [countDownToHideControls, setCountDownToHideControls] = useState(null)
    const handleFullScreen = useFullScreenHandle()
    const spacePress = useKeyPress(32)
    const escPress = useKeyPress(27)

    const barRef = useRef()

    useEffect(() => {
      if (isNull(videoPlayer) && videoPlayerRef.current) {
        setVideoPlayer(
          new Player(videoPlayerRef.current, {
            autoplay: 1,
            controls: false,
            title: false,
            texttrack: showCaptions,
            muted: 1,
          })
        )
      }
    }, [
      videoPlayerRef.current,
    ]) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
      if (videoPlayer) {
        videoPlayer.getDuration().then((duration) => {
          setDuration(duration)
        })
        videoPlayer.on("play", () => {
          setLoading(false)
          setPlaying(true)
          setPause(false)
        })
        videoPlayer.on("pause", () => {
          setPause(true)
        })
        videoPlayer.on("bufferstart", () => {
          setLoading(true)
        })
        videoPlayer.on("bufferend", () => {
          setLoading(false)
        })
      }
    }, [videoPlayer]) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
      if (videoPlayer) {
        if (!isPlaying) {
          videoPlayer.play()
          if (startTime) videoPlayer.setCurrentTime(startTime)
        }

        videoPlayer.on("timeupdate", ({ percent, seconds }) => {
          setPlaying(true)
          setProgress({
            progress: percent,
            progressSeconds: seconds,
          })
        })
      }
    }, [isPlaying]) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
      let timer
      if (countDownToHideControls === 5000) {
        setShowControls(true)
        timer = setTimeout(() => {
          setCountDownToHideControls(null)
          setShowControls(false)
        }, countDownToHideControls)
      }

      return () => {
        clearTimeout(timer)
      }
    }, [
      countDownToHideControls,
      showControls,
    ]) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
      if (videoPlayer) {
        if (!isPlaying) {
          videoPlayer.play()
        }
        if (isPlaying) {
          videoPlayer.pause()
        }
      }
    }, [spacePress]) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
      if (handleFullScreen.active) {
        handleFullScreen.exit()
      } else {
        if (escPress) onClose()
      }
    }, [escPress]) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
      if (videoPlayer) {
        if (showCaptions) {
          videoPlayer.getTextTracks().then((tracks) => {
            videoPlayer.enableTextTrack(tracks[0].language)
          })
        } else {
          videoPlayer.disableTextTrack()
        }
      }
    }, [showCaptions]) /* eslint-disable-line react-hooks/exhaustive-deps */

    useEffect(() => {
      if (videoPlayer) {
        if (!isPlaying) {
          videoPlayer.play()
        }
      }
    }, [videoPlayer])

    return (
      <FullScreen handle={handleFullScreen}>
        <div
          className={`video ${
            handleFullScreen.active ? "video-fullscreen" : ""
          }`}
          onClick={() => {
            if (videoPlayer) {
              videoPlayer.getPaused().then((paused) => {
                if (paused) videoPlayer.play()
                if (!paused) videoPlayer.pause()
              })
            }
          }}
          onKeyUp={(ev) =>
            handleKeyUp(ev, () => {
              if (videoPlayer) {
                videoPlayer.getPaused().then((paused) => {
                  if (paused) videoPlayer.play()
                  if (!paused) videoPlayer.pause()
                })
              }
            })
          }
          onMouseMove={() => {
            if (isPlaying) setCountDownToHideControls(5000)
          }}
          onMouseLeave={() => {
            if (isPlaying) setCountDownToHideControls(5000)
          }}
          role="button"
          tabIndex="0"
        >
          <iframe
            title="main-videoplayer-iframe"
            ref={videoPlayerRef}
            tabIndex="-1"
            aria-hidden="true"
            src={`https://player.vimeo.com/video/${getUrl(
              videoSrcURL
            )}?controls=0${startTime ? "#t=" + startTime : ""}`}
            width="100%"
            height="100%"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            texttrack={"en-US"}
            allow="autoplay; fullscreen"
            referrerPolicy="origin"
          ></iframe>

          <div className="control-layer">
            {isLoading && (
              <div className="loading-wrap">
                <div
                  style={{
                    transform: "translate(-30px, 30px)",
                    transformOrigin: "center center",
                  }}
                >
                  <Loading color={color} />
                </div>
              </div>
            )}
            {!isLoading && (!isPlaying || isPaused) && (
              <>
                <div className="play-wrap">
                  <Play size="large" tabIndex="0" />
                </div>
              </>
            )}
          </div>

          {isLastTenSeconds && !!nextProfile && (
            <VideoNext isPaused={isPaused} nextProfile={nextProfile} />
          )}
        </div>

        <VideoPlayerControls
          barRef={barRef}
          color={color}
          duration={duration}
          isPlaying={isPlaying}
          isPaused={isPaused}
          videoPlayer={videoPlayer}
          profileId={profileId}
          progress={progress}
          setPause={() => {
            videoPlayer.pause()
          }}
          setPlaying={() => {
            videoPlayer.play()
          }}
          setProgress={setProgress}
          showControls={showControls}
          handleFullScreen={handleFullScreen}
          hasTranscript={hasTranscript}
          showTranscript={showTranscript}
          setShowTranscript={setShowTranscript}
          hasCaptions={hasCaptions}
          showCaptions={showCaptions}
          setShowCaptions={setShowCaptions}
          onSeek={(progressSeconds) => {
            videoPlayer.setCurrentTime(progressSeconds)
          }}
        />
        {showTranscript && (
          <Transcript
            name={name}
            progress={progress}
            setProgress={setProgress}
            duration={duration}
            setShowTranscript={setShowTranscript}
            onSeek={(progressSeconds) => {
              videoPlayer.setCurrentTime(progressSeconds)
            }}
            isPlaying={isPlaying && !isPaused}
          />
        )}
      </FullScreen>
    )
  }
)

export default VideoPlayer
