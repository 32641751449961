import { navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { handleKeyUp } from "../../utils"
import { useInterval } from "../../utils/useInterval"
import SkipBar from "../Home/SkipBar"
import "./VideoNext.css"

const AUTOMATIC_REDIRECT = true
const TIME_DELAY = 2000

const VideoNext = ({ nextProfile, isPaused }) => {
  const [timeleft, setTimeLeft] = useState(TIME_DELAY)

  const profileUri = nextProfile.full_name.text.toLowerCase().replace(/ /g, "_")

  useInterval(
    () => {
      setTimeLeft(timeleft - 1)
    },
    AUTOMATIC_REDIRECT && 1000,
    []
  )

  useEffect(() => {
    if (timeleft < 1) {
      navigate(`/visiting-room/${profileUri}`)
    }
  }, [timeleft, profileUri])

  return (
    <div
      className="video-next fadein"
      onClick={() => {
        navigate(`/visiting-room/${profileUri}`)
      }}
      onKeyUp={(ev) =>
        handleKeyUp(ev, () => {
          navigate(`/visiting-room/${profileUri}`)
        })
      }
      role="link"
      tabIndex="0"
    >
      <GatsbyImage
        alt={`Next video: ${nextProfile.full_name.text}`}
        image={nextProfile?.profile_picture?.gatsbyImageData}
        imgStyle={{
          objectFit: "cover",
          filter: "brightness(0.5) saturate(0.5)",
        }}
      />
      <div className="video-next-text">
        <div>
          <p style={{ margin: 0, fontSize: "var(--font-small)" }}>Next visit</p>
          {nextProfile && (
            <p
              style={{
                margin: 0,
                fontFamily: "EB Garamond",
                fontSize: "var(--font-copy)",
              }}
            >
              {nextProfile.full_name.text}
            </p>
          )}
        </div>
        <div className="next-bar">
          <SkipBar barProgress={timeleft / TIME_DELAY} />
        </div>
      </div>
    </div>
  )
}

export default VideoNext
