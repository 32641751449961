import React, { useEffect, useRef, useState } from "react"
import { handleKeyUp } from "../../utils"
import useHasMounted from "../../utils/useHasMounted"
import Header from "../Header/Header"
import CrossClose from "../Symbols/CrossClose"
import "./Video.css"
import VideoPlayer from "./VideoPlayer"

const Video = ({
  name,
  color,
  image,
  onClose,
  profileId,
  isArchive,
  nextProfile,
  video_link,
  age_at_offense,
  current_age,
}) => {
  const hasMounted = useHasMounted()
  const params = new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  )

  const videoPlayerRef = useRef()
  const [duration, setDuration] = useState(null)
  const [showTranscript, setShowTranscript] = useState(
    params.get("transcript") === "true"
  )
  const [isLastTenSeconds, setIsLastTenSeconds] = useState(false)
  const [progress, setProgress] = useState({
    progress: 0,
    progressSeconds: 0,
  })

  useEffect(() => {
    setIsLastTenSeconds(
      duration ? duration - progress.progressSeconds < 15 : false
    )
  }, [progress, duration, setIsLastTenSeconds])

  if (!hasMounted) return null

  return (
    <div className={showTranscript ? "video-wrap transcript" : "video-wrap "}>
      <Header
        title={name}
        actions={null}
        hideMenu
        hideHelp
        classes="fadein"
        color={color}
      />
      <VideoPlayer
        videoSrcURL={video_link}
        videoTitle={null}
        color={color}
        onClose={onClose}
        duration={duration}
        setDuration={setDuration}
        name={name}
        age_at_offense={age_at_offense}
        current_age={current_age}
        nextProfile={nextProfile}
        isLastTenSeconds={isLastTenSeconds}
        setIsLastTenSeconds={setIsLastTenSeconds}
        profileId={profileId}
        progress={progress}
        setProgress={setProgress}
        ref={videoPlayerRef}
        startTime={params.get("t")}
        texttrack={params.get("texttrack")}
        hasCaptions={!isArchive}
        hasTranscript={isArchive}
        showTranscript={showTranscript}
        setShowTranscript={setShowTranscript}
        image={image}
      />
      <div
        className="close-video"
        onClick={() => {
          onClose()
        }}
        onKeyUp={(ev) =>
          handleKeyUp(ev, () => {
            onClose()
          })
        }
        role="button"
        tabIndex="0"
      >
        <CrossClose />
      </div>
    </div>
  )
}

export default Video
