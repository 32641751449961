import React, { useEffect, useRef, useState } from "react"
import { handleKeyUp } from "../../utils"
import { getSeconds } from "../../utils/index.js"
import Loading from "../Loading"
import CrossClose from "../Symbols/CrossClose"
import "./Transcript.css"
import TranscriptParagraph from "./TranscriptParagraph"

const Transcript = ({
  name,
  progress,
  setProgress,
  setShowTranscript,
  duration,
  isPlaying,
  onSeek,
}) => {
  const [isScrolling, setScrolling] = useState(false)
  const [transcript, setTranscript] = useState()
  const panelRef = useRef()
  const [error, setError] = useState(false)

  import(
    "../../content/transcripts/" +
      [name.replace(/ /g, "_").toLowerCase()] +
      ".json"
  )
    .then((trans) => {
      if (trans.default.transcript) setTranscript(trans.default.transcript)
      setError(false)
    })
    .catch((error) => {
      setError(true)
    })

  useEffect(() => {
    const activeElement = document.querySelector(".transcript-paragraph.active")
    if (activeElement) activeElement.scrollIntoView()
  }, [transcript])

  useEffect(() => {
    let scrollTimeout

    const handleScroll = (event) => {
      // Clear our timeout throughout the scroll
      window.clearTimeout(scrollTimeout)
      setScrolling(true)

      // Set a timeout to run after scrolling ends
      scrollTimeout = setTimeout(function () {
        // Run the callback
        setScrolling(false)
      }, 500)
    }

    panelRef.current.addEventListener("scroll", handleScroll, false)

    return () => {
      if (panelRef.current) {
        panelRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <div className="transcript-panel" ref={panelRef}>
      <div className="transcript-header">
        <h4>{name}</h4>
        <div
          onClick={() => {
            setShowTranscript(false)
          }}
          onKeyUp={(ev) =>
            handleKeyUp(ev, () => {
              setShowTranscript(false)
            })
          }
          role="button"
          tabIndex="0"
        >
          <CrossClose theme="light" />
        </div>
      </div>
      <div className="transcript-content">
        {!transcript && !error && <Loading hideTitle={true} />}
        {transcript &&
          !error &&
          transcript.map((paragraph, idx) => {
            if (!paragraph.speaker && !paragraph.time) return null

            const isActive =
              paragraph.upperBoundTime &&
              progress.progressSeconds < getSeconds(paragraph.upperBoundTime) &&
              paragraph.time &&
              progress.progressSeconds > getSeconds(paragraph.time)

            const isFirstActive =
              !idx || (idx !== 0 && transcript[idx - 1].time !== paragraph.time)

            const isPast =
              paragraph.upperBoundTime &&
              progress.progressSeconds > getSeconds(paragraph.upperBoundTime)

            return (
              <TranscriptParagraph
                isPast={isPast}
                isActive={isActive}
                isFirstActive={isFirstActive}
                setProgress={setProgress}
                getSeconds={getSeconds}
                paragraph={paragraph}
                duration={duration}
                handleKeyUp={handleKeyUp}
                onSeek={onSeek}
                isScrolling={isScrolling}
                isPlaying={isPlaying}
              />
            )
          })}
        {error && <p>Something went wrong loading the transcript.</p>}
      </div>
    </div>
  )
}

export default Transcript
